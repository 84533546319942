import { addPartMember, removePartMember } from 'api/events';
import BackButton from 'components/Shared/BackButton';
import MembersComponent from 'components/Shared/MembersComponent';
import { IonIcon } from 'components/UI/IonIcon';
import Layout from 'components/UI/Layout';
import { getConfig } from 'config/config';
import { useCustomHistory } from 'hooks/useCustomHistory';
import { useLanguage } from 'languages/languageContext';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { AddOrganizersModal } from 'scenes/CreateEvent/components/AddOrganizersModal';
import { TEventPart } from 'scenes/CreateEvent/components/EventParts/EventPart';
import { useAppSelector } from 'store';
import { getEventByIdRequest, getEventByIdSuccess } from 'store/actions/events';
import EditIcon from '@material-ui/icons/Edit';

const { theme } = getConfig();

type TMember = TEventPart['members'][0]['id'];

const PartPartcipants = () => {
  const history = useCustomHistory();
  const user = useAppSelector((state) => state.users.user);
  const { currentEvent: reduxCurrEvent } = useAppSelector((state) => state.events);
  const { eventLabels, kreiseSettings, btn } = useLanguage();
  const dispatch = useDispatch();
  const location = useLocation();
  // const currentEvent = reduxCurrEvent || location.state?.event;
  const currentEvent = reduxCurrEvent;
  const eventRegistrationType = currentEvent?.registration?.type;
  const defaultEventRegistrationType = eventRegistrationType === 'app';
  const eventId = useMemo(() => location.pathname.replace('/parts-participants/', ''), []);
  const preunfolded = location.state?.preunfolded ? [location.state?.preunfolded] : [];

  const [editMode, setEditMode] = useState(false);
  const [modal, setModal] = useState({ open: false, partId: null as string });
  const [unfolded, setUnfolded] = useState(preunfolded as string[]);

  useEffect(() => {
    if (!reduxCurrEvent) dispatch(getEventByIdRequest(eventId));
  }, []);

  const onUnfold = (partId: string) => {
    if (unfolded.includes(partId)) setUnfolded((c) => c.filter((id) => id !== partId));
    else setUnfolded((c) => [...c, partId]);
  };

  const onSelect = async (partId: string, member: string) => {
    const res: any = await addPartMember(partId, member);
    dispatch(getEventByIdSuccess({ ...currentEvent, parts: res.parts }));
  };

  const onDelete = async (partId: string, member: string) => {
    const res: any = await removePartMember(partId, member);
    dispatch(getEventByIdSuccess({ ...currentEvent, parts: res.parts }));
  };

  return (
    <Layout>
      <BackButton
        title={`${!defaultEventRegistrationType ? 'Interessenten' : eventLabels.participantLabel}`}
        onClick={history.goBack}
        additionalButton={
          <button onClick={() => setEditMode((c) => !c)}>
            {!editMode ? (
              <div
                style={{
                  color: theme.BACKGROUND_PRIMARY,
                  backgroundColor: theme.ACTIVE_INPUT,
                  width: 30,
                  height: 30,
                  borderRadius: 30,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <EditIcon color="inherit" />
              </div>
            ) : (
              <IonIcon
                name={'checkmark'}
                size={20}
                color={theme.BACKGROUND}
                style={{
                  backgroundColor: theme.ACTIVE_INPUT,
                  borderRadius: 40,
                  padding: 5,
                }}
              />
            )}
          </button>
        }
      />
      {currentEvent?.parts?.map((part: TEventPart) => {
        const isUnfolded = unfolded.includes(part._id);
        return (
          <div className="mb-2 p-2">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center justify-center">
                <h2 className="font-medium text-xl text-primary-txt px-2 py-1">{part.title}</h2>
                <button
                  className="flex items-center p-1 rounded-full duration-75 hover:bg-black/5"
                  onClick={() => onUnfold(part._id)}
                >
                  <IonIcon name={isUnfolded ? 'chevron-down' : 'chevron-up'} color={theme.BUTTON_SECONDARY} />
                </button>
              </div>
              {editMode && (
                <button
                  className="text-primary-btn duration-75 hover:bg-black/5 p-1 px-2 rounded-md"
                  onClick={() => {
                    setModal({ open: true, partId: part._id });
                    if (!isUnfolded) onUnfold(part._id);
                  }}
                >
                  add users
                </button>
              )}
            </div>
            <h3 className="px-2 text-primary-txt text-md">
              {eventLabels.participantLabel}: {part?.members?.length} {part?.maxMembers !== 0 && '/ ' + part.maxMembers}
            </h3>
            {isUnfolded &&
              part?.members?.map((member) => (
                <div key={member?._id} className="flex flex-row items-center justify-between">
                  <MembersComponent registrationType={!defaultEventRegistrationType} key={member?._id} like={member} />
                  {user?._id !== member?._id && editMode && (
                    <button onClick={() => onDelete(part._id, member.id._id)}>
                      <IonIcon name="trash-outline" size={32} color={theme.ERROR_PRIMARY} />
                    </button>
                  )}
                </div>
              ))}
            {part.maxMembers !== 0 && (
              <>
                <h3 className="px-2 text-primary-txt text-md">
                  {eventLabels.waitlist}: {part?.waitList?.length}
                </h3>
                {/* @ts-ignore */}
                {isUnfolded &&
                  part?.waitList?.map((member) => (
                    <div key={member?.user?._id} className="flex flex-row items-center justify-between">
                      <MembersComponent registrationType={!defaultEventRegistrationType} like={member.user} />
                      {user?._id !== member?.user && editMode && (
                        <button onClick={() => onDelete(part._id, member.user._id)}>
                          <IonIcon name="trash-outline" size={32} color={theme.ERROR_PRIMARY} />
                        </button>
                      )}
                    </div>
                  ))}
              </>
            )}
            <AddOrganizersModal
              open={modal.open}
              onClose={() => setModal((c) => ({ ...c, open: false, partId: null }))}
              values={[...part.members.map((el) => el.id), ...part.waitList.map((el) => el.user)]}
              onSelect={(value) => {
                onSelect(part._id, value._id);
              }}
              onDelete={(value) => {
                onDelete(part._id, value._id);
              }}
            />
          </div>
        );
      })}
    </Layout>
  );
};

export default PartPartcipants;
